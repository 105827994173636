import { COLORS } from '../../../common/colors';

export const dynamicStyling = {
  FORM_VIEW_DYNAMIC_STYLES : {
    DIG_COLUMN_STYLES: `
      flex: 1;
			&:nth-child(1) {
				position: relative;
    		left: 5px;
        flex: 0;
      }
      &:nth-child(2) {
      	flex: 0.4;
      }
      &:nth-child(2)::before {
        content: '';
        margin-left: 10px;
      }
      text-transform: capitalize;
      border: unset;
      &:nth-child(3) {
      	flex: 1.2;
      }
      &:nth-child(4) {
        flex: 3;
      }
      &:nth-child(5) {
        flex: 1.5;
      }
      &:nth-child(6) {
        flex: 1.5;
      }  
		`,
    DIG_ROW_STYLES: `
			  padding-bottom: 103px;
        margin-right: 35px;
        > div{
          .dig-toggle-switch {
            margin-top: 4px;
          }
          .dig-actions {
            text-align: center;
          }
            > span:nth-child(1) {
              flex: 0.31;
              text-align: center;
              display: flex;
    					position: relative;
    					justify-content: space-between;
            }
            > span:nth-child(2) {
            	flex: 0.7;
            	text-overflow: clip;
            }
            > span:nth-child(3),.dig-actions {
                flex: 3;
            }
            > span:nth-child(4) {
                position: relative;
      					left: -21px;
                flex: 6;
            }
             > span:nth-child(5) {
              	position: relative;
      					left: -11px;
                flex: 3;
            }
            > span:nth-child(6) {
                flex: 3;
            }
            > span:nth-child(7) {
                flex: 1;
            }
            border: 1px solid ${COLORS.TABLE.ROW.BORDER};
            height: 52px;
            margin: 5px;
            align-items: center;
            &:hover {
                background-color: rgba(80, 128, 255, 0.15);
            }
            // if lock label exists it should be the first child element
            // if not exists, don't set style on trigger type label
            .column-type >span:nth-child(1):not(.dig-trigger-type-label) {
                float: right;
                span {
                    margin: 0;
                }
            }
		`,
  },
  DRAFT_FORM_VIEW_DYNAMIC_STYLES: {
    DIG_COLUMN_STYLES: `
      text-transform: capitalize;
      border: unset;
      
	  &:nth-child(1) {
        flex: 0 0 auto;
      }
      &:nth-child(2) {
        flex: 6;
      }
      &:nth-child(5) {
      	flex: 1 0 140px;
      }
      &:nth-child(6) {
        flex: 0 0 120px;
      }
      &:nth-child(7) {
        flex: 0 0 33px;
      }
      &:nth-child(8) {
        flex: 0 0 58px;
      }
      &:nth-child(9) {
        flex: 3;
      }
		`,
    DIG_ROW_STYLES: `
		padding-bottom: 235px;
        margin-right: 35px;
        
        .dig-trigger-type-label, .dig-badge {
          margin-left: 0;
        }
        .status-published {
          border: 1px solid ${COLORS.PUBLISHED_BORDER};
        }
        .status-draft {
          border: 1px solid ${COLORS.DRAFT_BORDER};
        }
        .dig-point {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background: ${COLORS.DRAFT_POINT_COLOR};
          margin-left: 5px;
        }
        > div{
          .dig-actions {
            flex: 3;
            text-align: center;
            
            .publishDraft {
              border-bottom: 1px solid ${COLORS.SEPARATOR};
            }
            .context-menu-action:last-child {
                border-top: 1px solid ${COLORS.SEPARATOR};
            }
            .context-menu-action-icon {
              line-height: 1;
            }
          }
            > span:nth-child(1) {
              flex: 0 0 auto;
              text-align: center;
            }
            > span:nth-child(2) {
              flex: 6;
              cursor: pointer;
            }
            > span:nth-child(3) {
            	text-overflow: clip;
            }
            > span:nth-child(5) {
                flex: 1 0 140px;
            }
            > span:nth-child(6) {
              flex: 0 0 120px;
            }
            > span:nth-child(7) {
              flex: 0 0 33px;
              text-align: center;
              padding: 6px;
            }
            > span:nth-child(8) {
              flex: 0 0 58px;
              text-align: center;
              padding: 6px;
            }
            > span:nth-child(9) {
              flex: 3;
            }
            border: 1px solid ${COLORS.TABLE.ROW.BORDER};
            height: 52px;
            margin: 5px;
            align-items: center;
            &:hover {
                background-color: rgba(80, 128, 255, 0.15);
            }
        `,
  },
};
