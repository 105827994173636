import DigAutoSuggest from '@mdigital/components/dist/components/AutoSuggest';
import DigButton from '@mdigital/components/dist/components/Button';
import DigCheckbox from '@mdigital/components/dist/components/Checkbox';
import FileDownloader from '@mdigital/components/dist/components/FileDownloader';
import DigInput from '@mdigital/components/dist/components/Input';
import DigRadioButtons from '@mdigital/components/dist/components/RadioButtons';
import DigTitle from '@mdigital/components/dist/components/Title';
import DigToolbarDropdown from '@mdigital/components/dist/components/ToolbarDropdown';
import DigMessage from '@mdigital/components/dist/components/Message';
import AppNotification from './components/AppNotification';
import './components/DigComponents.common.css';
import DigCustomParametersContainer from './components/DigCustomParametersContainer';
import DigLanguageDropdown from './components/DigLanguageDropdown/DigLanguageDropdownWrapper';
import DigMenuContainer from './components/DigLeftMenuContainer/DigLeftMenuContainerWrapper';
import DigLocalizationModalContainer from './components/DigLocalizationModalContainer';
import DigMultiOptionEditor from './components/DigMultiOptionEditor';
import DigPackageHistory from './components/DigPackageHistory/DigPackageHistory';
import DigTable from './components/DigTable';
import DigToolbarButton from './components/DigToolbarButton';
import FormView from './components/FormView';
import MobileCodeTrigger from './components/MobileCodeTrigger';
import MobileInvitationDesign from './components/MobileInvitationDesign';
import ResourcesSelect from './components/ResourcesSelect';
import SdkIntegrationV2Modal from './components/SdkIntegrationV2Modal';
import UploaderView from './components/UploaderView';
import SystemUpdateContainer from './modals/freezmode/SystemUpdateContainer';
import AppRatingScreen from './pages/AppRating';
import FormDisplay from './pages/FormDisplay';
import Login from './pages/Login';
import Packages from './pages/Packages';
import ResourcesLibrary from './pages/ResourcesLibrary';
import ThankYouPrompt from './components/ThankYouPrompt';
import Message from '@mdigital/components/dist/components/Message';
import Intercom from './components/Intercom';
import Decibel from './components/Decibel';
import EmbeddedTargeting from './components/EmbeddedTargeting/EmbeddedTargetingContainer/EmbeddedTargetingContainer';
import QuestionScoreSelection from './components/QuestionScoreSelection';
import FormQuestionCounterPanel from './components/FormQuestionCounterPanel';
// eslint-disable-next-line no-unused-vars
import ngReact from 'ng-react-loader!ngreact';
import ReportFieldMappingModalContainer from './components/ReportFieldMappingModal/ReportFieldMappingModalContainer';
import ReusableCustomParametersContainer from './components/ReusableCustomParametersContainer/ReusableCustomParametersContainer';
import MediaCaptureFormatsPreview from './components/MediaCapture/MediaCaptureFormatsPreview';
import MediaCaptureResponseFormats from './components/MediaCapture/MediaCaptureResponseFormats';
import MediaCaptureAccessibility from './components/MediaCapture/MediaCaptureAccessibility';
import MobilePreviewIntegrationModal from './components/MobilePreviewIntegrationModal';
import GenericCustomCss from './components/GenericCustomCss/GenericCustomCss';
import SurveyUpgrade from './modals/SurveyUpgrade';
import WarningSign from './components/WarningSign';
import { ModalsContainer, ModalsService } from '@mdigital/components/dist/utils/modals';
import { AlchemyStyles, alchemyHoc } from './utils/alchemy';
import FormBasicSettings from './components/FormBasicSettings';
import { configureConditionalDisplay } from './modals/ConditionalDisplayModal';
import AccessDenied from './components/AccessDenied/AccessDenied';
import DomainsList from './pages/ApprovedDomains';
import { configureTriggeringModal } from './components/TriggeringModal';
import { configureFormsCardModals } from './components/FormsCardModals';
import { openEditPublishedFormDialog } from './components/EditPublishedFormDialog';
import PublishStateLabel from './components/PublishStateLabel/PublishStateLabel';
import { openConfirmDialog } from './components/ConfirmDialog';
import FormPublishSwitcher from './components/FormPublishSwitcher';
import { openFormVersionSelectDialog } from './components/FormVersionSelectDialog';
import AlchemyToast from './components/AlchemyToast/AlchemyToast';
import { openFormPublishApproverDialog } from './components/FormPublishApproverDialog';
//we use custom loader for ngreact because it doesnt support our use case(we load angular in index.html as global and react with webpack)
//once we migrate to load angular with webpack we can remove this loader

window.angular.module('NebReact', ['react'])
  .directive('reactDigTable', ['reactDirective', (reactDirective) => reactDirective(DigTable)])
  .directive('reactDigToolbarButton', ['reactDirective', (reactDirective) => reactDirective(DigToolbarButton)])
  .directive('reactDigAutoSuggest', ['reactDirective', (reactDirective) => reactDirective(DigAutoSuggest)])
  .directive('reactFileDownloader', ['reactDirective', (reactDirective) => reactDirective(FileDownloader)])
  .directive('reactDigMultiOptionEditor', ['reactDirective', (reactDirective) => reactDirective(DigMultiOptionEditor)])
  .directive('reactDigToolbarDropdown', ['reactDirective', (reactDirective) => reactDirective(DigToolbarDropdown)])
  .directive('reactDigPackageHistory', ['reactDirective', (reactDirective) => reactDirective(DigPackageHistory)])
  .directive('reactDigInput', ['reactDirective', (reactDirective) => reactDirective(DigInput)])
  .directive('reactDigTitle', ['reactDirective', (reactDirective) => reactDirective(DigTitle)])
  .directive('reactDigMessage', ['reactDirective', (reactDirective) => reactDirective(DigMessage)])
  .directive('reactDigCustomParametersContainer', ['reactDirective', (reactDirective) => reactDirective(DigCustomParametersContainer)])
  .directive('reactDigCheckbox', ['reactDirective', (reactDirective) => reactDirective(DigCheckbox)])
  .directive('reactDigRadioButtons', ['reactDirective'], (reactDirective) => reactDirective(DigRadioButtons))
  .directive('reactDigButton', ['reactDirective', (reactDirective) => reactDirective(DigButton)])
  .directive('reactDigLocalizationModalContainer', ['reactDirective', (reactDirective) => reactDirective(DigLocalizationModalContainer, ['addAlert'])])
  .directive('reactDigLanguageDropdown', ['reactDirective', (reactDirective) => reactDirective(DigLanguageDropdown)])
  .directive('reactFormView', ['reactDirective', (reactDirective) => reactDirective(FormView)])
  .directive('reactUploaderView', ['reactDirective', (reactDirective) => reactDirective(UploaderView)])
  .directive('reactAppRatingPage', ['reactDirective', (reactDirective) => reactDirective(AppRatingScreen , ['store' , 'onSuccessMessage' , 'onErrorMessage' , 'helpCenterLinks' , 'isDarkModeEnabled', 'isNativeAlertRevamp'])])
  .directive('reactDigMenuContainer', ['reactDirective', (reactDirective) => reactDirective(DigMenuContainer)])
  .directive('reactFormDisplay', ['reactDirective', (reactDirective) => reactDirective(FormDisplay)])
  .directive('reactMobileInvitationDesign', ['reactDirective', (reactDirective) => reactDirective(MobileInvitationDesign, ['store', 'onModelChanged', 'initialValues' , 'helpCenterLinks' , 'isDarkModeEnabled' , 'isNativeAlertRevamp', 'isReadOnlyMode'])])
  .directive('reactMobileCodeTrigger', ['reactDirective', (reactDirective) => reactDirective(MobileCodeTrigger)])
  .directive('reactSdkIntegrationV2Modal', ['reactDirective', (reactDirective) => reactDirective(SdkIntegrationV2Modal)])
  .directive('reactLogin', ['reactDirective', (reactDirective) => reactDirective(Login)])
  .directive('reactPackages', ['reactDirective', (reactDirective) => reactDirective(Packages)])
  .directive('reactResourcesSelect', ['reactDirective', (reactDirective) => reactDirective(ResourcesSelect, ['placeholder', 'noOptionsHeader', 'onSelect', 'resourceId', 'types','isDisabled'])])
  .directive('reactResourcesLibrary', ['reactDirective', (reactDirective) => reactDirective(ResourcesLibrary)])
  .directive('reactSystemUpdateContainer', ['reactDirective', (reactDirective) => reactDirective(SystemUpdateContainer)])
  .directive('reactAppNotification', ['reactDirective', (reactDirective) => reactDirective(AppNotification, ['addAlert'])])
  .directive('reactMessage', ['reactDirective', (reactDirective) => reactDirective(Message)])
  .directive('reactIntercom', ['reactDirective', (reactDirective) => reactDirective(Intercom, ['store'])])
  .directive('reactDecibel', ['reactDirective', (reactDirective) => reactDirective(Decibel)])
  .directive('reactThankYouPrompt', ['reactDirective', (reactDirective) => reactDirective(ThankYouPrompt , ['onModelChanged' , 'initialValues', 'propertyType' , 'thankYouPromptHelpLink' , 'submitButtonColors' , 'thankYouPromptHeaderContract', 'isPoweredByEnable', 'isReusableResourcesEnabled', 'isFormV2Enabled' , 'isDarkModeEnabled', 'darkHeaderContract'])])
  .directive('reactQuestionScoreSelection', ['reactDirective', (reactDirective) => reactDirective(QuestionScoreSelection, ['selectedScore', 'onSelect'])])
  .directive('reactFormQuestionCounterPanel', ['reactDirective', (reactDirective) => reactDirective(FormQuestionCounterPanel, ['max', 'count'])])
  .directive('reactEmbedTargeting', ['reactDirective', (reactDirective) => reactDirective(EmbeddedTargeting, ['formData', 'setFormDataRules','isReadOnlyMode', 'setPageElementId','setFormError'])])
  .directive('reactReusableCustomParametersContainer', ['reactDirective', (reactDirective) => reactDirective(ReusableCustomParametersContainer)])
  .directive('reactMediaCaptureFormatsPreview', ['reactDirective', (reactDirective) => reactDirective(MediaCaptureFormatsPreview, ['mediaCaptureDynamicFieldContract' , 'propertyType'])])
  .directive('reactMediaCaptureResponseFormats', ['reactDirective', (reactDirective) => reactDirective(MediaCaptureResponseFormats, ['mediaCaptureDynamicFieldContract' , 'removeMediaCaptureFormats' , 'addMediaCaptureFormats' , 'setMaxVideoLength' , 'setAudioPlaceholderText', 'propertyType'])])
  .directive('reactMediaCaptureAccessibility', ['reactDirective', (reactDirective) => reactDirective(MediaCaptureAccessibility, ['mediaCaptureDynamicFieldContract' , 'setAudioAltText' , 'setVideoAltText' , 'setUploadMediaAltText' , 'propertyType'])])
  .directive('reactReportFieldMappingModalContainer', ['reactDirective', (reactDirective) => reactDirective(ReportFieldMappingModalContainer)])
  .directive('reactMobilePreviewIntegrationModal', ['reactDirective', (reactDirective) => reactDirective(MobilePreviewIntegrationModal)])
  .directive('reactGenericCustomCss', ['reactDirective', (reactDirective) => reactDirective(GenericCustomCss)])
  .directive('reactSurveyUpgrade', ['reactDirective', (reactDirective) => reactDirective(SurveyUpgrade)])
  .directive('reactWarningSign', ['reactDirective', (reactDirective) => reactDirective(WarningSign)])
  .directive('reactFormBasicSettings', ['reactDirective', (reactDirective) => reactDirective(FormBasicSettings , ['onModelChanged', 'getInitialValues', 'isDarkModeEnabled'])])
  .directive('reactModalsContainer', ['reactDirective', (reactDirective) => reactDirective(alchemyHoc(ModalsContainer))])
  .directive('reactAlchemyGlobalStyles', ['reactDirective', (reactDirective) => reactDirective(AlchemyStyles)])
  .directive('reactAccessDenied', ['reactDirective', (reactDirective) => reactDirective(AccessDenied)])
  .directive('reactDomainsList', ['reactDirective', (reactDirective) => reactDirective(alchemyHoc(DomainsList))])
  .directive('reactFormPublishSwitcher', ['reactDirective', (reactDirective) => reactDirective(alchemyHoc(FormPublishSwitcher), ['displayedVersion', 'changeDisplayedVersion'])])
  .directive('reactPublishLabel', ['reactDirective', (reactDirective) => reactDirective(PublishStateLabel, ['isPublished', 'digTooltip'])])
  .directive('reactAlchemyToast', ['reactDirective', (reactDirective) => reactDirective(alchemyHoc(AlchemyToast),['messages'])])
  .service('ModalsService', () => ModalsService)
  .service('ConfirmDialogService', () => ({
    openConfirmDialog,
    openFormVersionSelectDialog,
    openFormPublishApproverDialog,
  }))
  .service('ConditionalDisplayService', () => ({
    configureConditionalDisplay,
  }))
  .service('TriggeringModalService', () => ({
    configureTriggeringModal,
    configureFormsCardModals,
  }))
  .service('EditPublishedService', () => ({
    openEditPublishedFormDialog,
  }));
